<div class="course-assign-license-error-dialog">
  <div mat-dialog-title>
    {{ 'Save Failed' | translate }}
    <cl-functional-button
      mat-dialog-close
      [icon]="Icon.cancel"
      class="course-assign-license-error-dialog__close"
      size="32px"></cl-functional-button>
  </div>
  <div mat-dialog-content>
    <div class="course-assign-license-error-dialog__info">
      <p>
        {{ 'No available licenses to assign course.' | translate }}
      </p>
      <p *ngIf="isTeacher$ | async">
        {{
          'We recommend you discuss with your school administrator to review license availability and ensure your' +
            ' licensing needs are met to support your students.' | translate
        }}
      </p>
      <p *ngIf="isSchoolOrDistrictAdmin$ | async">
        {{ 'To view license availability, go to ' | translate }}
        <span>
          <a
            [routerLink]="redirectLink"
            mat-dialog-close>
            {{ '"License Allocations" in the "Organizations" tab' | translate }}
          </a>
        </span>
        {{ '. Further information regarding license distribution can be found there.' | translate }}
      </p>
      <p *ngIf="isSchoolAdmin$ | async">
        {{
          'If your licensing needs are not met we recommend you discuss with your District Administrator.'
            | translate
        }}
      </p>
      <p *ngIf="isDistrictAdmin$ | async">
        {{
          'Please reach out to your School Relations contact if additional licenses are needed to support' +
            ' your district licensing needs.' | translate
        }}
      </p>
    </div>
  </div>
  <div mat-dialog-actions>
    <cl-button mat-dialog-close>{{ 'OK' | translate }}</cl-button>
  </div>
</div>
