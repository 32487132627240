import { Component, OnInit } from '@angular/core';
import { Icon } from '@wam/icons';
import { select, Store } from '@ngrx/store';
import { State, UserRole } from '@wam/shared';
import { authenticationSelectors } from '@wam/authentication';
import { map, mergeMap, take } from 'rxjs/operators';
import { OrganizationsDataService } from '@app/organizations/organizations-data.service';
import { combineLatest, Observable, of } from 'rxjs';
import { OrganizationType } from '@app/organizations/organization.model';

@Component({
  selector: 'course-assign-license-error-dialog',
  templateUrl: './course-assign-license-error-dialog.component.html',
  styleUrls: ['./course-assign-license-error-dialog.component.scss'],
})
export class CourseAssignLicenseErrorDialogComponent implements OnInit {
  Icon = Icon;

  constructor(
    private store: Store<State>,
    private organizationService: OrganizationsDataService,
  ) {}

  redirectLink: string;

  private roles$ = this.store.pipe(select(authenticationSelectors.getUserRoles));

  isTeacher$ = this.roles$.pipe(
    map(
      (roles) => !roles.includes(UserRole.SCHOOL_ADMIN) && !roles.includes(UserRole.DISTRICT_ADMIN),
    ),
  );

  isSchoolAdmin$ = this.roles$.pipe(map((roles) => roles.includes(UserRole.SCHOOL_ADMIN)));

  isDistrictAdmin$ = this.roles$.pipe(map((roles) => roles.includes(UserRole.DISTRICT_ADMIN)));

  isSchoolOrDistrictAdmin$ = this.roles$.pipe(
    map(
      (roles) => roles.includes(UserRole.SCHOOL_ADMIN) || roles.includes(UserRole.DISTRICT_ADMIN),
    ),
  );

  redirectLink$(): Observable<string> {
    return combineLatest([this.isSchoolAdmin$, this.isDistrictAdmin$]).pipe(
      mergeMap(([isSchoolAdmin, isDistrictAdmin]) => {
        if (!isSchoolAdmin && !isDistrictAdmin) return of(null);
        return this.getOrganizationId(
          isSchoolAdmin ? OrganizationType.SCHOOL : OrganizationType.CUSTOMER,
        );
      }),
      map((orgId) => (orgId ? `/organizations/${orgId}/license-allocations` : '/organizations')),
      take(1),
    );
  }

  getOrganizationId(organizationType: OrganizationType): Observable<string> {
    return this.organizationService.getOrganizations().pipe(
      map((organizations) => {
        if (organizationType === OrganizationType.SCHOOL) {
          const schools = organizations.filter(({ type }) => type === OrganizationType.SCHOOL);
          if (schools.length > 1) {
            return null;
          }
        }
        return organizations.find(({ type }) => type === organizationType)?.sourcedId ?? null;
      }),
    );
  }

  ngOnInit(): void {
    this.redirectLink$().subscribe((redirectLink) => {
      this.redirectLink = redirectLink;
    });
  }
}
